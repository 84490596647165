import update from 'lodash/update'
import mapValues from 'lodash/mapValues'

// decimal.js is often unused, but only adds 12k to a 200k-ish build.
// TODO: we can save a little bandwidth by lazy-loading this.
// Not yet worth the trouble, though.
import Decimal from 'decimal.js'

const help =
`This is the Swarm Simulator Javascript API. Use this to automate your game.

Most API responses use [promises](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Using_promises).

For example, to log the current state of the game:

    swarmApi.get()
    .then(function(response) {
      console.log(response)
    })
    .catch(function(error) {
      console.error(error)
    })

Or, a more concise way to write the same thing:

    swarmApi.get().then(console.log, console.error)

Arguments are always passed as objects with named fields. For example,

    swarmApi.buy({item: 'drone', count: '100%'})

Inspect the \`swarmApi\` object in the JS console to see all this API offers.

Your first use of this API will grant a hidden achievement. The intention is
to record the date you started automating things; the achievement's *absence*
is evidence that you've made it this far by hand.

Available functions:
* swarmApi.get(): see the current state of the game as a JSON object.

  This requires using promise-based callback functions. For example:

      swarmApi.get().then(function(response) { console.log(response) })

* swarmApi.buy({item: string, count: string}): buy a unit, upgrade, or spell.

  "count" is not a JS number, but the string you'd enter into Swarm Simulator's
  input box. For example, "100%".

  Inspect \`swarmApi.gameData\` for unit/upgrade/spell names. Note that the
  names in URLs and the user interface are *not* the same names used here.

* swarmApi.undo(): try to undo your last recent action.
* swarmApi.nav({path: string}): change the URL without a full page reload.
* swarmApi.reset(): reset your saved game. Cannot undo.
`

const apiState = {
  nextCallId: 1,
  pendingCalls: {}
}
const connectFunction = (app, name) => (args) => new Promise((resolve, reject) => {
  try {
    const requestId = apiState.nextCallId + ''
    apiState.nextCallId += 1
    args = args || {}
    apiState.pendingCalls[requestId] = {name, requestId, args, resolve, reject}
    app.ports.apiRequest.send({name, requestId, args})
  }
  catch (error) {
    reject({requestId, error, message: 'javascript exception calling app.ports.apiReq.send'})
  }
})
export function connect(app, flags) {
  const api = {
    help,
    // static data
    environment: flags.environment,
    version: flags.version,
    gameData: flags.gameData,
  }
  // connect all available functions
  for (let fn of ['get', 'buy', 'undo', 'nav', 'reset']) {
    api[fn] = connectFunction(app, fn)
  }
  // listen for Elm's responses
  app.ports.apiResponse.subscribe(response => {
    const pending = apiState.pendingCalls[response.requestId]
    delete apiState.pendingCalls[response.requestId]
    if (!pending) {
      console.error('app.ports.apiResponse returned a response with no pending api call', {requestId: response.requestId})
    }
    else if (!!response.error) {
      pending.reject(response)
    }
    else {
      // convert elm's json response to appropriate JS types.
      // Callers can override constructors with arguments to any function:
      // {date, decimal}
      const date = pending.args.date || (t => new Date(t))
      const decimal = pending.args.decimal || (d => new Decimal(d))
      update(response, 'timestamp', date)
      if (response.game) {
        update(response, 'game.created', date)
        update(response, 'game.achievements', a => mapValues(a, date))
        update(response, 'game.units', u => mapValues(u, decimal))
        update(response, 'game.upgrades', u => mapValues(u, decimal))
        response.game.toString = function() {
          return JSON.stringify(this, null, 2)
        }
      }
      pending.resolve(response)
    }
  })
  window.swarmApi = api
  console.info('Hi there! Trying to automate Swarm Simulator? Type "swarmApi" or "swarmApi.help".')
}
