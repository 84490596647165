import $ from 'jquery'

export function connect(app) {
  app.ports.kongregateInit.subscribe(() => {
    const {kongregate} = window.parent
    if (!!kongregate) {
      // we're loaded within kongregate_shell.html
      console.log('swarmsim: found kongregate_shell api', kongregate)
      kongregateConnect(app, kongregate)
    }
    else {
      // no kongregate_shell.html - load kong js ourselves
      $.getScript('https://cdn1.kongregate.com/javascripts/kongregate_api.js')
      .done(() => {
        // https://docs.kongregate.com/docs/javascript-api
        const {kongregateAPI} = window
        console.log('swarmsim: loading kongregate api', kongregateAPI)
        kongregateAPI.loadAPI(() => {
          kongregateConnect(app, kongregateAPI.getAPI())
        })
      })
    }
  })
}

function kongregateConnect(app, kongregate) {
  // console.log('swarmsim: kongregate api loaded', kongregate)
  sendLogin(app, kongregate)
  kongregate.services.addEventListener("login", event => {
    sendLogin(app, kongregate)
  });
  app.ports.kongregateShowRegistrationBox.subscribe(() => {
    kongregate.services.showRegistrationBox()
  })
  app.ports.kongregateShowKredPurchaseDialog.subscribe(() => {
    kongregate.mtx.showKredPurchaseDialog()
  })
  app.ports.kongregateRequestUserItemListReq.subscribe(() => {
    kongregate.mtx.requestUserItemList(null, res => {
      // console.log("requestUserItemList", res)
      app.ports.kongregateRequestUserItemListRes.send(res)
    })
  })
  app.ports.kongregatePurchaseItemsReq.subscribe(item => {
    kongregate.mtx.purchaseItems([item], res => {
      app.ports.kongregatePurchaseItemsRes.send({item, success: res.success})
    })
  })

  let kongregateStatsLastSubmitted = new Date(0)
  app.ports.kongregateSubmitStats.subscribe(stats => {
    // throttle kongregate stats reports
    let now = new Date()
    if ((now.getTime() - kongregateStatsLastSubmitted.getTime()) >= 60 * 1000) {
      kongregateStatsLastSubmitted = now
      console.log('kongregateSubmitStats', stats)
      for (let key in stats) {
        kongregate.stats.submit(key, stats[key])
      }
    }
  })
}

function sendLogin(app, kongregate) {
  const data = {
    userId: kongregate.services.getUserId()+'',
    username: kongregate.services.getUsername(),
    gameAuthToken: kongregate.services.getGameAuthToken(),
    isGuest: kongregate.services.isGuest(),
  }
  // console.log('swarmsim: kongregate login', data.userId, data.username, data.isGuest)
  app.ports.kongregateLogin.send(data)
}
