// Everything to do with saved data. Encode/decode, read/write.
//
// Reads old-swarmsim saves:
// https://github.com/swarmsim/swarm/blob/master/app/scripts/services/session.coffee
// Must be written in JS, not Elm, for access to lz-string. (Easier comparison with the Coffeescript version doesn't hurt either.)
import lzstring from 'lz-string'
import map from 'lodash/map'
import range from 'lodash/range'

const prefix = btoa("Cheater :(\n\n")
// The encoded string starts with an encoded version number. Older savestates
// might not, so if this is missing, no biggie.
export const versionDelim = '|'
const persistVersion = "1.2.0"
const versionWhitelist = {
  // we no longer autoincrement the serialized version, so this is fine.
  // (why not? - elm versions work too differently from old-swarmsim's versions)
  // we'll increment it manually by changing this file if it's ever necessary.
  [persistVersion]: true,
  // the 1.2.0 beta had this as its version for a while. no harm in keeping it
  // around. (we should've used "2.0.0" anyway, so it'll be easy to distinguish
  // if we ever need to)
  "2.0": true,
}
// 1.1.[0...22] - we haven't released that many yet, but some breathing room
for (let i of range(0, 22+1)) {
  versionWhitelist['1.1.'+i] = true
}
// 1.0.[0...66]
for (let i of range(0, 66+1)) {
  versionWhitelist['1.0.'+i] = true
}

// Based on old-swarmsim session._saves:
// https://github.com/swarmsim/swarm/blob/6c6b73889d253e5382cbcf2bdd6270b171a4484e/app/scripts/services/session.coffee#L53
export function encode(json) {
  // encoding's much simpler than decoding, since we're not worried about legacy.
  var encoded = JSON.stringify(json)
  encoded = lzstring.compressToBase64(encoded)
  encoded = btoa(persistVersion) + versionDelim + prefix + encoded
  return encoded
}
// Based on old-swarmsim session._loads:
// https://github.com/swarmsim/swarm/blob/6c6b73889d253e5382cbcf2bdd6270b171a4484e/app/scripts/services/session.coffee#L110
export function decode(encoded) {
  // ignore whitespace
  encoded = encoded.replace(/\s+/g, '')
  // try to parse the version prefix. Some older savestates might not have it.
  var decodedVersion = null
  if (encoded.indexOf(versionDelim) >= 0) {
    [decodedVersion, encoded] = encoded.split(versionDelim)
    decodedVersion = atob(decodedVersion)
  }
  // old-swarmsim does some complex version validation to deal with a bunch of old legacy savestates.
  // Those kinds of savestates are mostly gone these days - let's simplify to a whitelist.
  if (!versionWhitelist[decodedVersion]) {
    throw new Error("invalid save decodedVersion: "+decodedVersion)
  }
  encoded = encoded.substring(prefix.length)
  encoded = lzstring.decompressFromBase64(encoded)
  // below formats a save for use in a test case
  // console.log(JSON.stringify(encoded))
  const decoded = JSON.parse(encoded)
  // console.log(decoded)
  return decoded
}
