import './main.css'
// https://getbootstrap.com/docs/4.2/getting-started/introduction/
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootswatch/dist/cyborg/bootstrap.css'
import $ from 'jquery'
import 'bootstrap/dist/js/bootstrap.js'

// https://fontawesome.com/how-to-use/on-the-web/referencing-icons/basic-use
// https://fontawesome.com/icons?d=gallery
import '@fortawesome/fontawesome-free/css/all.css'

import { Elm } from './Main.elm'
import gameData from 'json-loader!@erosson/xlsx-loader!../public/gamedata.ods'
import en_us from '../public/lang/en-us.json'
import {version} from '../public/version.json'
import changelog from '!!raw-loader!../../../CHANGELOG.md'
import * as registerServiceWorker from './registerServiceWorker'
import * as persist from './persist'
import * as kongregate from './kongregate'
import * as api from './api'
import konami from 'konami'

window.persist = persist  // for debugging
const persistKey = 'swarm-elm'
const sessionKey = persistKey + ':session-ticket'
const rememberKey = persistKey + ':remember-id'
const translatorKey = persistKey + ':translator'
const coffeePersistKey = 'v0.2'
const coffeeKongPersistKey = 'v0.2-kongregate'
// localStorage.setItem('swarm-elm:error', 'fail')


// google analytics
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', 'UA-53523462-4');

function jsonParseOr(val) {
  try {
    return JSON.parse(val)
  }
  catch (e) {
    console.error(e)
    return val
  }
}
const flags = {
  environment: process.env.NODE_ENV,
  hostname: document.location.hostname,
  version,
  gameData,
  lang: {en_us},
  translator: jsonParseOr(localStorage.getItem(translatorKey), null),
  changelog: changelog.split("\n---\n")[1],
}
const app = Elm.Main.init({flags})
// https://developers.google.com/web/ilt/pwa/caching-files-with-service-worker
self.addEventListener('install', function(event) {
  event.waitUntil(
    caches.open('css').then(function(cache) {
      const themes =
        ['/css/bootstrap']
        .concat([
          'cerulean',
          'cosmo',
          'cyborg',
          'darkly',
          'flatly',
          'journal',
          'litera',
          'lumen',
          'lux',
          'materia',
          'minty',
          'pulse',
          'sandstone',
          'simplex',
          'sketchy',
          'slate',
          'solar',
          'spacelab',
          'superhero',
          'united',
          'yeti',
        ].map(name => '/css/bootswatch/'+name))
      const files = themes.map(theme => [theme+'/bootstrap.min.css', theme+'bootstrap.min.css.map']).flat()
      // console.log(files)
      cache.addAll(files)
      console.log('cached '+files.length+' files')
    })
  );
});
// spamming error messages... I'll sort this out later
// registerServiceWorker.register()
registerServiceWorker.unregister()

function connect() {
  // Let Elm know when the developer pushes an update so we can nag players to hit refresh after the update's downloaded
  registerServiceWorker.subscribe(event => {
    app.ports.serviceWorker.send(event)
  })

  // Keep Elm updated when another tab changes localstorage
  // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Responding_to_storage_changes_with_the_StorageEvent
  window.addEventListener('storage', (e) => {
    if (e.key == persistKey) {
      // console.log('storage event', e)
      app.ports.persistRead.send(persistRead())
    }
    else if (e.key == sessionKey) {
      // console.log('storage event', e)
      readUserSessionTicket(app)
    }
    else if (e.key == rememberKey) {
      // console.log('storage event', e)
      // app.ports.readUserRememberId.send(localStorage.getItem(rememberKey))
    }
    else if (e.key == translatorKey) {
      // JSON.parse may throw an error here; let it
      app.ports.translatorRead.send(JSON.parse(localStorage.getItem(translatorKey)))
    }
  })
  // Communicate with localstorage on other subdomains. This is how
  // elm.swarmsim.com fetches saved games from www.swarmsim.com's localstorage.
  const wwwIframeReady = new Promise((wwwIframeResolve) => {
    window.addEventListener("message", (e) => {
      const wwwIframe = document.getElementById('www-localstorage')
      if (e.source == wwwIframe.contentWindow) {
        // console.log('window.postMessage received from wwwIframe', e)
        if (e.data.type == 'ready') {
          wwwIframeResolve(e)
        }
        else if (e.data.type == 'get' && [coffeePersistKey, coffeeKongPersistKey].includes(e.data.key)) {
          const msg = persistDecode(e.data.value)
          if (msg.status !== 'empty') {
            console.log("local save is empty, but "+e.origin+"@"+e.data.key+" save is nonempty! Importing it.")
            app.ports.persistRead.send(msg)
          }
        }
      }
      else {
        console.warn('window.postMessage received from unknown source', e)
      }
    }, false)
  })

  // Notify Elm before closing the page, to save the game
  let unloaded = false
  function onUnload(e) {
    if (!unloaded) {
      unloaded = true
      app.ports.onUnload.send()
    }
  }
  window.addEventListener('beforeunload', onUnload)
  window.addEventListener('unload', onUnload)
  // Save the game while the page is closing.
  // Normally Elm sends these requests, but http requests during page close are
  // a bit special. Sending from JS gives us more control.
  app.ports.onUnloadWrite.subscribe(({game, playfabUrl})=> {
    // console.log('onUnloadWrite', playfabUrl, game)
    // The request below should match PlayFab.elm:writeState.
    const auth = localStorage.getItem(sessionKey)
    const url = playfabUrl + '/Client/UpdateUserData'
    const encoded = persist.encode(game)
    const body = encodeChunks('state', 10000, encoded)
    // Beacons are the Right Way to do this, but they seem to ignore the X-Authentication header. Sadness.
    // Instead, we use a synchronous xhr, which stalls page close but writes the save.
    //if (false && navigator.sendBeacon) {
    //  const headers = {type: 'text/plain', 'X-Authentication': localStorage.getItem(sessionKey)}
    //  const headers = {type: 'application/json', 'X-Authentication': localStorage.getItem(sessionKey)}
    //  navigator.sendBeacon(url, new Blob([JSON.stringify(body)], headers))
    //}
    //else {
      // also write localstorage on close, so the two are an exact match when the page is loaded.
      // This eases offlineSync conflict resolution for the simple case of single-online-device.
      localStorage.setItem(persistKey, encoded)
      // fallback to synchronous xmlhttprequest. This stalls the page close, but works.
      const xhr = new XMLHttpRequest()
      xhr.open('POST', url, false) // "false" makes it synchronous. Async is ignored in onunload.
      xhr.setRequestHeader('Content-Type', 'application/json')
      xhr.setRequestHeader('X-Authentication', localStorage.getItem(sessionKey))
      xhr.onload = console.log
      xhr.onerror = console.warn
      xhr.send(JSON.stringify(body))
    //}
  })

  // Send Elm the fully-encoded save state whenever it writes to localstorage
  //
  // Elm can't fully-encode saved games by itself - `./persist.js` and some
  // external libraries help with this. The options screen needs the most recent
  // fully-encoded save for exporting, though! So, update Elm after each
  // localstorage write.
  app.ports.persistWrite.subscribe(json => {
    // console.log('persistWrite', json)
    if (json) {
      let encoded = persist.encode(json)
      localStorage.setItem(persistKey, encoded)
      app.ports.persistExport.send({status: "success", value: encoded})
    }
    else {
      localStorage.removeItem(persistKey)
      app.ports.persistExport.send({status: "empty"})
    }
  })

  // Tell Elm what we just decoded after the player imports a saved game
  //
  // Just like with exports, elm can't fully-decode imported saves.
  app.ports.persistImport.subscribe(raw => {
    const res = persistDecode(raw)
    // console.log('persistImport', raw, res)
    app.ports.persistImportRead.send(res)
  })

  // When the user logs in to PlayFab, save their session ticket to localstorage.
  app.ports.writeUserSessionTicket.subscribe(ticket => {
    console.log('writeUserSessionTicket', ticket)
    if (ticket) {
      localStorage.setItem(sessionKey, ticket)
    }
    else {
      localStorage.removeItem(sessionKey)
    }
  })
  app.ports.writeUserRememberId.subscribe(id => {
    console.log('writeUserRememberId', id)
    if (id) {
      localStorage.setItem(rememberKey, id)
    }
    else {
      localStorage.removeItem(rememberKey)
    }
  })

  // When PlayFab fetches a user, decode the user's saved game.
  app.ports.playfabDecodeSaveReq.subscribe(({userId, encoded}) => {
    const res = persistDecode(encoded)
    res.userId = userId
    app.ports.playfabDecodeSaveRes.send(res)
  })

  konami(() => {
    app.ports.konami.send(null)
  })
  app.ports.translatorWrite.subscribe(val => {
    if (val) {
      localStorage.setItem(translatorKey, JSON.stringify(val))
    }
    else {
      localStorage.removeItem(translatorKey)
    }
  })

  // Connect other files.
  kongregate.connect(app)
  api.connect(app, flags)

  // Finally, on page load, tell Elm what's in localstorage.
  //
  // This goes last: if any of the subscriptions above fail, we want to exit
  // before running this, because the UI makes this one very obvious. Avoid
  // silent failures, or even quiet failures.
  //
  // Don't send this with flags - we want to eventually support remote saves.
  const firstRead = persistRead()
  app.ports.persistRead.send(firstRead)
  if (firstRead.status == 'empty') {
    wwwIframeReady.then(e => {
      const key = query().kongregate ? coffeeKongPersistKey : coffeePersistKey
      // console.log("local save was empty. asking "+e.origin+"@"+key+" for a save...")
      e.source.postMessage({type: 'get', key}, e.origin)
    })
  }
  readUserSessionTicket(app)
}

function readUserSessionTicket(app) {
  app.ports.readUserSessionTicket.send({
    sessionTicket: localStorage.getItem(sessionKey),
    rememberId: localStorage.getItem(rememberKey),
  })
}
function persistRead() {
  return persistDecode(localStorage.getItem(persistKey))
}
function persistDecode(raw) {
  if (!raw) return {status: "empty"}
  try {
    return {status: "success", value: persist.decode(raw), raw}
  }
  catch (e) {
    console.error(e)
    return {status: "error", message: e.message, raw}
  }
}
/**
Split a large save into chunks and encode it for UpdateUserData.
This duplicates ChunkedState.elm, but it's short and simple enough that I'm not
too worried. ChunkedState.elm is much longer (and safer/less fragile!) due to
types and decoder boilerplate.
*/
function encodeChunks(prefix, size, value) {
  const numChunks = Math.max(1, Math.ceil(value.length / size))
  const chunks = {}
  chunks[prefix] = value.substr(0, size)
  for (let i=1; i < numChunks; i++) {
    chunks[prefix + i] = value.substr(size * i, size)
  }
  return {Data: chunks, KeysToRemove: [prefix + numChunks]}
}
/**
 * parse the query string into an object
 */
function query(search0=document.location.search) {
  const search = search0.replace(/^\?/, '')
  if (search == '') return {}
  const pairs = search.split('&').map(p => p.split('='))
  const ret = {}
  for (let [k,v] of pairs) {
    ret[decodeURIComponent(k)] = decodeURIComponent(v)
  }
  return ret
}

connect()
